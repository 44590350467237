import React from "react";
import dropdown from "../assets/images/components/dropdownArrow.svg";

const QuestionComponent = ({ value }) => {
  return (
    <div className="w-full h-max 2xl:py-12 2xl:px-56 lg:py-8 lg:px-40 md:px-28 md:py-6 px-5 py-3 flex justify-around items-center">
      <div className="collapse collapse-arrow  w-full">
        <input type="checkbox" className="peer" />
        <div className="collapse-title collapse-arrow text-primary peer-checked:bg-alt  peer-checked:text-primary 2xl:text-2xl lg:text-lg">
          {value.question}
        </div>
        <div className="collapse-content text-primary peer-checked:bg-alt peer-checked:text-primary">
          <p className="2xl:text-xl lg:text-md">
           {value.answer}
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionComponent;
