const data = [
  {
    day: "Fri, April 1st",
    time: "17:00 IST",
    session: "Introduction session",
  },
  {
    day: "Fri, April 1st",
    time: "23:59 IST",
    session: "Event begins!",
  },
  {
    day: "Sat, April 2nd",
    time: "17:00 IST",
    session: "AMA with the team",
  },
  {
    day: "Sun, April 3rd",
    time: "23:59 IST",
    session: "Event end",
  },
  {
    day: "Mon, April 4th",
    time: "17:00 IST",
    session: "Results",
  },
];

export default data;
