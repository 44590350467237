import React from "react";
import data1 from "./RowOneData";
import MemberCard from "./MemberCard";

const AwesomeTeam = () => {
  return (
    <div className='h-max w-full py-20  '>
      <div className='h-full w-full'>
        <div className='w-full'>
          <div className='block relative'>
            <h3 className='text-secondary bg-primary px-6 py-1 absolute -top-6 left-1/2 rounded-full w-max 2xl:text-2xl shadow-mintflick lg:text-xl md:text-lg text-lg text-center font-black rotate-9 -ml-6'>
              Awesome
            </h3>
          </div>
          <h1 className='text-white 2xl:text-6xl lg:text-4xl md:text-4xl text-3xl text-center font-black  -mt-2'>
            TEAM
          </h1>
        </div>
        <div className=' flex justify-center'>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-8 lg:gap-16'>
            {data1.map((value, i) => {
              return (
                <div className='scale-90 lg:scale-100 col-span-1 mx-auto'>
                  <MemberCard
                    key={i}
                    name={value.name}
                    role={value.role}
                    image={value.img}></MemberCard>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwesomeTeam;
