import React from "react";
import logo from "../../assets/images/logos/mintflick-head.svg";
import linkedin from "../../assets/images/linkedin.svg";
import twitter from "../../assets/images/twitter.svg";
import instagram from "../../assets/images/instagram.svg";
import discord from "../../assets/images/discord.svg";
import { useLocation } from "react-router-dom";

const Social = () => {
  const location = useLocation();

  return (
    <div className="h-max w-full 2xl:py-14 lg:py-10 md:py-8 md:px-5 px-4 pt-5 md:pt-0 md:pb-0 pb-8 ">
      <div className="w-full h-full flex flex-col md:flex-row justify-center md:items-center">
        <div className="flex justify-center">
          <p className="text-primary 2xl:text-6xl lg:text-4xl md:text-4xl text-4xl font-bold flex items-center mr-3 ">
            FOLLOW
            <span className="flex justify-center items-center">
              <img
                src={logo}
                className="2xl:mx-6 lg:mx-3 md:mx-2 mx-4 lg:h-14 2xl:h-28  md:h-14 h-14 w-auto hover:scale-90 transform transition-all duration-200 delay-75 ease-in-out"
              />
            </span>
            ON
          </p>
        </div>
        <div className="flex justify-center mt-4 md:mt-0">
          {!location.pathname.includes("event") ? (
            <a
              href="https://discord.gg/xZavZyAbx4"
              target="_blank"
              className="hover:scale-90 transform transition-all duration-200 delay-75 ease-in-out"
            >
              <img
                src={discord}
                className="lg:mx-3 md:mx-1.5 mx-2 lg:h-14 2xl:h-20 md:h-16 h-16"
              />
            </a>
          ) : (
            <></>
          )}
          <a
            href="https://twitter.com/DBeatsLive"
            target="_blank"
            className="hover:scale-90 transform transition-all duration-200 delay-75 ease-in-out"
          >
            <img
              src={twitter}
              className="lg:mx-3 md:mx-1.5 mx-2  lg:h-14 2xl:h-20 md:h-16 h-16"
            />
          </a>
          <a
            href="https://www.instagram.com/dbeats.app"
            target="_blank"
            className="hover:scale-90 transform transition-all duration-200 delay-75 ease-in-out"
          >
            <img
              src={instagram}
              className="lg:mx-3 md:mx-1.5 mx-2  lg:h-14 2xl:h-20 md:h-16 h-16"
            />
          </a>
          <a
            href=" https://www.linkedin.com/company/dbeats"
            target="_blank"
            className="hover:scale-90 transform transition-all duration-200 delay-75 ease-in-out"
          >
            <img
              src={linkedin}
              className="lg:mx-3 md:mx-1.5 mx-2  lg:h-14 2xl:h-20 md:h-16 h-16"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Social;
