import React from "react";
import SectionGreeting from "../SectionHead/SectionGreeting";
import SectionHeadText from "../SectionHead/SectionHeadText";
import livepeer from "../../assets/images/livepeer.svg";
import f from "../../assets/images/f.svg";
import superfluid from "../../assets/images/superfluid.svg";
import purple from "../../assets/images/purple.svg";

const Partners = () => {
  return (
    <div className="h-max w-full py-24   lg:px-20 2xl:px-32 md:px-12 px-3 bg-primary">
      <div className="h-full w-full">
        <div className="w-full">
          <div className="block relative">
            <h3 className="text-alt bg-white px-6 py-1 absolute -top-7 sm:-top-8  right-1/2 rounded-full w-max 2xl:text-3xl shadow-mintflick  lg:text-xl md:text-lg text-lg text-center font-black -rotate-9">
              Supercool
            </h3>
          </div>
          <h1 className="text-alt 2xl:text-6xl lg:text-4xl md:text-4xl text-3xl text-center font-black -mt-2">
            PARTNERS
          </h1>
        </div>
        <div className="flex md:flex-row flex-col w-full justify-around lg:mt-20 2xl:mt-32 md:mt-20">
          <div className="flex justify-around md:w-1/2 w-full mt-14 md:mt-0">
            <img
              src={livepeer}
              className="lg:h-14 2xl:h-20 md:h-12 h-12 w-auto"
            ></img>
            <img src={f} className="lg:h-14 2xl:h-20 md:h-12 h-12 w-auto"></img>
          </div>
          <div className="flex flex-row-reverse md:flex-row justify-around md:w-1/2 w-full mt-10 md:mt-0">
            <img
              src={superfluid}
              className="lg:h-14 2xl:h-20 md:h-12 h-12 w-auto"
            ></img>
            <img
              src={purple}
              className="lg:h-14 2xl:h-20 md:h-12 h-12 w-auto"  
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
