import React from "react";
import NFTfromStreamsVideo from "../../assets/videos/NFTfromLivestreams.gif";
import Card3 from "../WhyTheyUseIt/Card3";

const LiveStreams2 = () => {
  return (
<div className='relative flex items-center justify-center my-40  px-10 gap-10 xl:gap-24 2xl:gap-36 '>
      <div className='hidden lg:block group  z-50 relative '>
        <div className="w-full mr-52">
          <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 -rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
            <img
              alt="NFT from Livestreams"
              className=" rounded-md"
              src={NFTfromStreamsVideo}
            />
          </div>
          <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 ">
            <img
              alt="NFT from Livestreams"
              className=" rounded-md"
              src={NFTfromStreamsVideo}
            />
          </div>
          <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
            <img
              alt="NFT from Livestreams"
              className=" rounded-md"
              src={NFTfromStreamsVideo}
            />
          </div>
          </div>
      </div>
      <div className="z-50 lg:ml-36">
        <Card3
          className=""
          headBefore=""
          headTitle="NFT from Livestreams"
          headAfter={`  Make the best out of your Livestreams with 1-click ${(
            <br />
          )}
            NFT minting during streams.`}
          desc={
            "Capture the best moments out of your live streams & make NFT drops without leaving your stream."
          }
          cards={
            <div className="scale-75 absolute z-50 left-1/2 -translate-x-1/2 lg:translate-x-1/2 translate-y-36">
              <div className="relative z-50 group ">
                <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 -rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
                  <img
                    alt="NFT from Livestreams"
                    className=" rounded-md"
                    src={NFTfromStreamsVideo}
                  />
                </div>
                <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 ">
                  <img
                    alt="NFT from Livestreams"
                    className=" rounded-md"
                    src={NFTfromStreamsVideo}
                  />
                </div>
                <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
                  <img
                    alt="NFT from Livestreams"
                    className=" rounded-md"
                    src={NFTfromStreamsVideo}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default LiveStreams2;
