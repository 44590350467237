import React from "react";
import token from "../../assets/images/token.svg";

const SuperfansCard = (props) => {
  return (
      <div className="flex flex-col justify-center items-center bg-alt/60 backdrop-blur-[24px] shadow-mintflick h-72 w-60 rounded-lg  px-8 py-12 ">
          <p
            className={`flex text-primary text-xl font-bold mb-4`}
          >
            {props.price} USDC
          </p>
          <p
            className={`text-white  text-sm font-bold  w-max ${props.background} p-1 rounded-md`}
          >
            {props.package}
          </p>
          <div className="font-semibold  text-white   pt-4  text-sm  text-center">
            {props.benefits}
          </div>
      
    </div>
  );
};

export default SuperfansCard;
