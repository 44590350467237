import Aniket from "../../assets/images/team/Aniket.png";
import Digvijay from "../../assets/images/team/Digvijay.png";
import Rushi from "../../assets/images/team/Rushi.png";
import SahilPanjabi from "../../assets/images/team/SahilPanjabi.png";
import Kelewala from "../../assets/images/team/Kelewala.png";
import Prasad from "../../assets/images/team/Prasad.png";
import Saurabh from "../../assets/images/team/Saurabh.png";
import Abhishek from "../../assets/images/team/Abhishek.png";
import Sahil from "../../assets/images/team/Sahil.png";

const data1 = [
  {
    name: "Aniket Tandale",
    role: "COO",
    img: Aniket,
  },
  {
    name: "Rushikesh Kardile",
    role: "CEO",
    img: Rushi,
  },
  {
    name: "Digvijay Dhamale",
    role: "CPO",
    img: Digvijay,
  },
  {
    name: "Sahil Punjabi",
    role: "Fullstack developer",
    img: SahilPanjabi,
    m: "-mt-0.5",
  },

  {
    name: "Prasad Karmalkar",
    role: "Fullstack developer",
    img: Prasad,
  },
  {
    name: "Harsh Chavan",
    role: "UI/UX Designer ",
    img: Kelewala,
  },
  {
    name: "Saurabh Dalvi",
    role: "Illustrator",
    img: Saurabh,
  },
  {
    name: "Abhishek Hande",
    role: "Devops",
    img: Abhishek,
  },
  // {
  //   name: "Sahil Shingate",
  //   role: "Fullstack developer",
  //   img: Sahil,
  // },
];

export default data1;
