export default [
    {
      question: "How to connect to wallet?",
      answer:
        "Mintflick is integrated with Web3auth, which gives you options to create or connect your wallet from email, Google, social media handles, or wallets like meta mask and Torus.",
    },
    {
      question: "How to link wallet to Mintflick account?",
      answer:
        "You can use your social handles, email or decenteralise wallet and you can directly link your wallets by clicking a suitable option on the Login screen.",
    },
    {
      question: "How to mint NFT?",
      answer:
        'While posting your thoughts, photos, videos, music, or polls, you will find an option to "mint as NFT". You need to click on this option to mint your content as NFT on Mintflick.',
    },
    {
      question: "How to make a livestream?",
      answer:
        'On the homescreen, you can find the option "GO LIVE". Once you click on it, you will be redirected to the live streaming page with the necessary information like the streaming key and URL. You must link the stream key in Streamlab OBS before you can start a live session on Mintflick.',
    },
    {
      question: "How to create superfan plans?",
      answer:
        'Go to the profile page. You can find the option "Setup Superfan." Click on it and you can create your own customised superfan plans for your users.',
    },
    {
      question: "Does minting NFT require any gas fees?",
      answer:
        "Absolutely not. Minting NFT is free on Mintflick. Just click on it and mint it.",
    },
    {
      question: "How to buy NFT?",
      answer:
        'Every NFT post footer has the option to "BUY THIS NFT". Once you click on it, you can see details of the owner and the price of NFT. You can buy this NFY by clicking "Buy NOW" or you can make an offer to the owner by clicking "MAKE AN OFFER".',
    },
    {
      question: "What is NFT? How they are stored?",
      answer:
        "Non-fungible tokens (NFTs) are cryptographic assets on a blockchain with unique identification codes and metadata that distinguish them from each other. NFTs are stored on a digital ledger called the blockchain. Each NFT has a specific token ID that is linked to a smart contract, which is stored in various blocks on the blockchain. Contrary to popular belief, NFTs are not stored in your wallet.",
    },
    {
      question: "How to become a superfan?",
      answer: `On Feed Post, please click on the right corner three vertical dots to open the option menu where you will find " Join Superfan." Once you click on it, a pop-up will open with the creator's set of superfan plans. You can click on your required plan and proceed with on-ramp payment to become a superfan. Note : If the creator has not setup any Superfan plans, you will not be able to see the option "Join Superfan".`,
    },
    {
      question: "What is Mintflick?",
      answer: `MintFlick is a decentralized Social media & NFT marketplace on Blockchain for Creators, Game Streamers, Bloggers, Music Artists and everyone else. We are building a decentralized community where users can mint NFTs of their videos, create NFTs during Live streams or post photos and make it an NFT.`,
    },
    {
      question: "What is superfan?",
      answer: `A superfan is a creator's user who buys a creator's customised plan.`,
    },
    {
      question: "Is my data secured? How is it stored?",
      answer: `Mintflick stores data on IPFS (Interplanetary File System) which is on blockchain. The records on a blockchain are secured through cryptography. Network participants have their own private keys that are assigned to the transactions they make and act as a personal digital signature.`,
    },
  ];