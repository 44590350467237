import React from "react";
import FAQ from "./FAQ/FAQ.js";
import Head from "./Head/Head";
import Help from "./Help/Help.js";
import Participation from "./Particpation/Participation";
import Prizes from "./Prizes/Prizes";
import Schedule from "./Schedule/Schedule";

const Stream3weekend = () => {
  return (
    <div className="h-full w-full overflow-hidden">
      <Head />
      <Prizes />
      <Schedule />
      <Participation />
      <FAQ />
      <Help />
    </div>
  );
};

export default Stream3weekend;
