import React from "react";
import SectionHeadText from "../../../components/SectionHead/SectionHeadText";
import SectionParaText from "../../../components/SectionHead/SectionParaText";

const Prizes = () => {
  return (
    <div id="prizes" className="h-max w-full mt-20">
      <div className="h-full w-full">
        <div className="w-full">
          <SectionHeadText title="₹ 50,000" />
          <SectionParaText para={<p>Available to be won</p>} />
        </div>
        <div className="w-full 2xl:mt-24 lg:mt-16 mt-12">
          <p className="text-white 2xl:text-6xl lg:text-4xl md:text-4xl text-3xl text-center font-bold">
            Prizes
          </p>
          <div className="flex flex-col items-center 2xl:mt-5 lg:mt-2.5 mt-2.5">
            <div className="flex">
              <div className="2xl:h-56 lg:h-44 2xl:w-72 lg:w-52 h-40 w-40 2xl:p-10 lg:p-5 p-5 text-center text-white bg-alt 2xl:mr-2.5 lg:mr-1.5 mr-1.5 flex flex-col justify-center rounded-xl">
                <h2 className="2xl:text-lg lg:text-md text-sm">₹ 8,000</h2>
                <h1 className="2xl:text-md lg:text-sm text-md 2xl:mt-2 lg:mt-1 mt-0.5">
                  SUPERFAN
                </h1>
                <p className="2xl:text-sm lg:text-xs text-xs 2xl:mt-1 lg:mt-0.5 mt-1">
                  Highest Superfan
                  <br /> subscriptions
                </p>
              </div>
              <div className="2xl:h-56 lg:h-44 2xl:w-72 lg:w-52 h-40 w-40 2xl:p-10 lg:p-5 p-5 text-center text-white bg-alt 2xl:ml-2.5 lg:ml-1.5 ml-1.5 flex flex-col justify-center rounded-xl">
                <h2 className="2xl:text-lg lg:text-md text-sm">₹ 8,000</h2>
                <h1 className="2xl:text-md lg:text-sm text-md 2xl:mt-2 lg:mt-1 mt-0.5">
                  NFT
                </h1>
                <p className="2xl:text-sm lg:text-xs text-xs 2xl:mt-1 lg:mt-0.5 mt-1">
                  Most Expensive NFT
                  <br /> Sold
                </p>
              </div>
            </div>
            <div className="flex 2xl:mt-5 lg:mt-2.5 mt-2.5">
              <div className="2xl:h-56 lg:h-44 2xl:w-72 lg:w-52 h-40 w-40 2xl:p-10 lg:p-5 p-5 text-center text-white bg-alt 2xl:mr-2.5 lg:mr-1.5 mr-1.5 flex flex-col justify-center rounded-xl">
                <h2 className="2xl:text-lg lg:text-md text-sm">₹ 4,500</h2>
                <h1 className="2xl:text-md lg:text-sm text-md 2xl:mt-2 lg:mt-1 mt-0.5">
                  SUBSCRIBERS
                </h1>
                <p className="2xl:text-sm lg:text-xs text-xs 2xl:mt-1 lg:mt-0.5 mt-1">
                  Highest subscribed
                  <br /> user
                </p>
              </div>
              <div className="2xl:h-56 lg:h-44 2xl:w-72 lg:w-52 h-40 w-40 2xl:p-10 lg:p-5 p-5 text-center text-white bg-alt 2xl:ml-2.5 lg:ml-1.5 ml-1.5 flex flex-col justify-center rounded-xl">
                <h2 className="2xl:text-lg lg:text-md text-sm">₹ 4,500</h2>
                <h1 className="2xl:text-md lg:text-sm text-md 2xl:mt-2 lg:mt-1 mt-0.5">
                  VIEWERS
                </h1>
                <p className="2xl:text-sm lg:text-xs text-xs 2xl:mt-1 lg:mt-0.5 mt-1">
                  Highest number of <br />
                  viewers during a stream
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prizes;
