import React from "react";

const SectionHeadText = (props) => {
  return (
    <h1 className='text-primary 2xl:text-6xl lg:text-4xl md:text-4xl text-2xl text-center font-bold 2xl:mt-1 lg:mt-0.5'>
      {props.title}
    </h1>
  );
};

export default SectionHeadText;
