import React from "react";

const Navbar = () => {
  return (
    <div className="w-full 2xl:h-20 lg:h-14 md:h-12 h-14 bg-secondary flex justify-center items-center shadow-md z-50">
      <h1 className="text-white 2xl:text-2xl lg:text-xl md:text-lg text-md text-center">
        You love creating content? Become a creator on{" "}
        <span className="text-primary font-bold">MintFlick</span> now!
      </h1>
    </div>
  );
};

export default Navbar;
