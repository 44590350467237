import React from "react";
import Investor from "./Investor";
import Social from "./Social";

const Footer = () => {
  return (
    <div className="bg-alt" id="footer">
      <Investor />
      <Social />
    </div>
  );
};

export default Footer;
