import React from "react";
import community from "../../../assets/images/community.jpg";
import Card3 from "../Card3";

const Community2 = () => {
  return (
    <div className='relative flex items-center justify-center my-40  px-10 gap-10 xl:gap-24 2xl:gap-36 '>
         <div className="z-50 lg:mr-36">
        <Card3
          className=""
          headBefore=""
          headTitle="COMMUNITY CHANNELS"
          headAfter={"Voice  Text Channels with community chatrooms. Host live podcasts or schedule events.  You are in control."}
          desc={
            "Run your community in the same place as you sell digital NFTs, Music, Art, host Events, post Articles much more. Get the conversation going."
          }
          cards={
            <div className="scale-75 absolute z-50 left-1/2 -translate-x-1/2 lg:translate-x-1/2 translate-y-36">
              <div className="relative z-50 group ">
                <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 -rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
                  <img
                    alt="NFT from Livestreams"
                    className=" rounded-md"
                    src={community}
                  />
                </div>
                <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 ">
                  <img
                    alt="NFT from Livestreams"
                    className=" rounded-md"
                    src={community}
                  />
                </div>
                <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
                  <img
                    alt="NFT from Livestreams"
                    className=" rounded-md"
                    src={community}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className='hidden lg:block scale-110 group  z-50 relative '>
        <div className="w-full mr-52">
          <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 -rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
            <img
              alt="NFT from Livestreams"
              className=" rounded-md"
              src={community}
            />
          </div>
          <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 ">
            <img
              alt="NFT from Livestreams"
              className=" rounded-md"
              src={community}
            />
          </div>
          <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 h-fit w-96 p-2 rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 rotate-9 group-hover:rotate-0  transform transition-all duration-500 delay-75 ease-in-out ">
            <img
              alt="NFT from Livestreams"
              className=" rounded-md"
              src={community}
            />
          </div>
          </div>
      </div>
     
    </div>
  );
};

export default Community2;
