import React from "react";
import SectionHeadText from "../../../components/SectionHead/SectionHeadText";

const Participation = () => {
  return (
    <div className="h-max w-full mt-40 bg-secondary 2xl:py-40 lg:py-24 md:py-36 py-14">
      <div className="h-full w-full">
        <div className="w-full">
          <SectionHeadText title="HOW DO I PARTICIPATE?" />
          <div className="flex flex-col items-center w-full justify-center md:mt-10 mt-7 mt-7">
            <div className="text-white">
              <h2 className="2xl:text-3xl lg:text-xl text-lg font-bold">
                STEP 1 :
              </h2>
              <p className="2xl:mb-5 lg:mb-2.5 mb-1.5 2xl:text-2xl text-md lg:text-lg">
                Create a new account on MintFlick
              </p>
              <a
                href="https://beta.dbeats.live"
                target="_blank"
                className="2xl:px-14 2xl:py-3 lg:px-9 lg:py-1.5 px-8 py-1.5 bg-primary rounded-sm 2xl:text-sm lg:text-xs text-xs"
              >
                SIGN UP
              </a>
            </div>
            <div className="text-white 2xl:mt-20 lg:mt-12 mt-6 2xl:-ml-10 lg:-ml-8 -ml-8">
              <h2 className="2xl:text-3xl lg:text-xl text-lg font-bold">
                STEP 2 :
              </h2>
              <p className="2xl:mb-5 lg:mb-2.5 mb-1.5 2xl:text-2xl lg:text-lg text-md">
                Fill out this form -{" "}
                <span className="text-primary">
                  <a href="/">click here</a>
                </span>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex w-full justify-center ">
          
        </div> */}
      </div>
    </div>
  );
};

export default Participation;
