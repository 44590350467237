import React from "react";
import SuperfansCard from "../../Superfans/SuperfansCard";
import Card3 from "../Card3";

const Superfans2 = () => {
  return (
    <div className='relative flex items-start justify-center my-40  px-10 gap-10 xl:gap-24 2xl:gap-36 '>
        <div className='z-50 '>
        <Card3
          className=''
          headBefore=''
          headTitle='SUPERFANS'
          headAfter={
            "Let your most passionate fans support your creative work via monthly memberships paid to you in cryptos"
          }
          desc={
            "You can let your fans become active participants in the work they love by offering them a monthly membership."}
          cards={
            <div className='scale-75 group absolute z-50 left-1/2 -translate-x-1/2 lg:translate-x-1/2 translate-y-3'>
          <div className='w-full flex justify-center items-start group  '>
            <div className='h-60   w-60 -rotate-6 -mr-36 sm:-mr-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-mr-3 '>
            <SuperfansCard
              price="5"
              package="SILVER"
              benefits={
                <ul className="flex flex-col">
                  <li>Early Access</li>
                  <li>Discounts</li>
                  <li>Merchandise</li>
                </ul>
              }
              class="hidden md=block"
              background=" bg-super_silver"
            />   
            </div>
            <div className='h-60   w-60  group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 z-50'>
            <SuperfansCard
              price="10"
              package="GOLD"
              benefits={
                <ul className="flex flex-col">
                  <li>Early Access</li>
                  <li>Discounts</li>
                  <li>Merchandise</li>
                  <li>Weekly Meets</li>
                  <li>Special Episodes</li>
                </ul>
              }
              background="bg-super_gold"
            />
            </div>
            <div className='h-60   w-60 rotate-6 -ml-36 sm:-ml-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 '>
            <SuperfansCard
              price="15"
              package="SUPERFAN"
              benefits={
                <ul className="flex flex-col">
                  <li>Early Access</li>
                  <li>Discounts</li>
                  <li>Merchandise</li>
                  <li>Fan-Only Emojis</li>
                  <li>Custom Voicemails</li>
                </ul>
              }
              background="bg-super_platinum"
            /> 
            </div>
          </div>
        </div>
          }
        />
      </div>
      
      <div className='hidden lg:block group  z-50  '>
        <div className='w-full flex justify-center items-start group '>
          <div className='h-60   w-60 -rotate-6 -mr-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-mr-3 '>
          <SuperfansCard
              price="5"
              package="SILVER"
              benefits={
                <ul className="flex flex-col">
                  <li>Early Access</li>
                  <li>Discounts</li>
                  <li>Merchandise</li>
                </ul>
              }
              class="hidden md=block"
              background=" bg-super_silver"
            />   
          </div>
          <div className='h-60  scale-105 group-hover:scale-100 w-60  group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 z-50'>
          <SuperfansCard
              price="10"
              package="GOLD"
              benefits={
                <ul className="flex flex-col">
                  <li>Early Access</li>
                  <li>Discounts</li>
                  <li>Merchandise</li>
                  <li>Weekly Meets</li>
                  <li>Special Episodes</li>
                </ul>
              }
              background="bg-super_gold"
            />
            </div>
          <div className='h-60   w-60 rotate-6 -ml-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 '>
          <SuperfansCard
              price="15"
              package="SUPERFAN"
              benefits={
                <ul className="flex flex-col">
                  <li>Early Access</li>
                  <li>Discounts</li>
                  <li>Merchandise</li>
                  <li>Fan-Only Emojis</li>
                  <li>Custom Voicemails</li>
                </ul>
              }
              background="bg-super_platinum"
            /> 
          </div>
        </div>
      </div>
      
    </div>
   
  );
};

export default Superfans2;
