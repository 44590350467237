import React from "react";
import SocialMediaCard2 from "../../SocialMedia/SocialMediaCard2";
import Card3 from "../Card3";
import socialImg4 from "../../../assets/images/socialImg4.png";
import socialImg5 from "../../../assets/images/socialImg5.png";
import socialImg6 from "../../../assets/images/socialImg6.png";

const Marketplace = () => {
  return (
    <div className='relative flex items-start justify-center my-40  px-10 gap-10 xl:gap-24 2xl:gap-36 '>
      <div className='hidden lg:block group  z-50  '>
        <div className='w-full flex justify-center items-center group  '>
          <div className='h-60   w-60 -rotate-6 -mr-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-mr-3 '>
            <SocialMediaCard2
              post={socialImg5}
              videoname='Meta owl'
              name='orion'
              price='50'
              days=''
            />
          </div>
          <div className='h-60   w-60  group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 z-50 scale-105 group-hover:scale-100'>
            <SocialMediaCard2
              post={socialImg4}
              videoname='Meta Cat'
              name='orion'
              price='50'
              days=''
            />
          </div>
          <div className='h-60   w-60 rotate-6 -ml-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 '>
            <SocialMediaCard2
              post={socialImg6}
              videoname='Meta dog'
              name='orion'
              price='50'
              days=''
            />
          </div>
        </div>
      </div>
      <div className='z-50  '>
        <Card3
          className=''
          headBefore='NFT'
          headTitle='MARKETPLACE'
          headAfter={
            "Import your NFTs from Opensea or Publish MintFlick exclusive collections."
          }
          desc={
            "Built on top of Polygon chain means all your experience will be gasless, swift & super-easy. Buying & Selling NFTs will be much smoother with our experience focused Marketplace."
          }
          cards={
            <div className='scale-75 group absolute z-50 left-1/2 -translate-x-1/2 lg:translate-x-1/2 translate-y-3'>
              <div className='w-full flex justify-center items-start group  '>
                <div className='h-60   w-60 -rotate-6 -mr-36 sm:-mr-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-mr-3 '>
                  <SocialMediaCard2
                    post={socialImg5}
                    videoname='Meta owl'
                    name='orion'
                    price='50'
                    days=''
                  />
                </div>
                <div className='h-60   w-60  group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 z-50'>
                  <SocialMediaCard2
                    post={socialImg4}
                    videoname='Meta Cat'
                    name='orion'
                    price='50'
                    days=''
                  />
                </div>
                <div className='h-60   w-60 rotate-6 -ml-36 sm:-ml-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 '>
                  <SocialMediaCard2
                    post={socialImg6}
                    videoname='Meta dog'
                    name='orion'
                    price='50'
                    days=''
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Marketplace;
