import { Disclosure } from "@headlessui/react";

export default function DisclosureComponent(props) {
  return (
    <div className="w-full lg:px-4 px-6 2xl:pt-16 lg:pt-10 pt-5">
      <div className="lg:w-2/5 w-full p-2 mx-auto bg-alt">
        <Disclosure className="mb-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full 2xl:px-6 lg:px-4 2xl:py-2 lg:py-1 px-1.5 py-1 2xl:text-xl lg:text-md text-md text-left text-primary bg-alt  ">
                <span>{props.value.question}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${
                    open ? "transform rotate-180" : ""
                  } 2xl:w-7 2xl:h-7 lg:h-5 lg:w-5 h-5 w-5 text-primary`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Disclosure.Button>
              <Disclosure.Panel className="2xl:px-6 lg:px-4 px-2 2xl:pt-4 lg:pt-2 2xl:pb-2 lg:pb-1 2xl:text-lg lg:text-sm text-sm text-white">
                If you're unhappy with your purchase for any reason, email us
                within 90 days and we'll refund you in full, no questions asked.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
