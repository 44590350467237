const data = [
  {
    question: "How do I create a new account?",
  },
  {
    question: "How do I setup my web3 wallet?",
  },
  {
    question: "How do I start streaming?",
  },
  {
    question: "How do I setup my Superfan plans?",
  },
  {
    question: "How do I become a creators Superfan?",
  },
];

export default data;
