import React from "react";
import linkedin from "../../assets/images/investorLinkedin.svg";

const Investor = () => {
  return (
    <div className='h-max w-full flex justify-center py-24 footerbg'>
      <div className='h-max lg:w-2/3 md:w-3/4 w-full  lg:p-7 2xl:px-12 md:p-3 p-3 px-8  '>
        <h1 className='text-white bg-primary pb-1  rounded-full px-8 inline-block lg:text-left 2xl:text-5xl lg:text-4xl text-2xl md:text-4xl font-bold     md:mt-0'>
          Become an Investor
        </h1>
        <p className='text-white 2xl:text-3xl lg:text-xl  lg:text-left md:text-lg md:mt-2  2xl:mt-5 lg:mt-2.5 mt-2'>
          Let's build a platform which both Creators &amp; Audience love.
          <br className='hidden md:block' />
          Partner with us in the new era of Creators Economy on Web3
        </p>
        <div className='w-full flex    lg:justify-start'>
          <p className='text-white 2xl:text-3xl lg:text-xl md:text-lg md:mt-7 2xl:mt-12 lg:mt-8 mt-2'>
            Connect with the founder on :{" "}
          </p>
          <a
            className='cursor-pointer'
            href='https://www.linkedin.com/in/rushikeshkardile/'
            target='_blank'
            rel='noreferrer'>
            <img
              src={linkedin}
              className='cursor-pointer hover:scale-99 2xl:h-12 lg:h-8 md:h-7 h-5 w-auto 2xl:mt-9 lg:mt-6 md:mt-6 2xl:ml-5 lg:ml-3 md:ml-3 ml-2 mt-2'
            />
          </a>
        </div>
        <a className='cursor-pointer ' href='/'>
          <p className='text-white mt-3 cursor-pointer bg-primary-400 border-primary border-2 w-max px-4 py-2 rounded-md shadow-md text-center font-semibold lg:text-left 2xl:text-2xl lg:text-xl md:text-lg hover:scale-99 transform transition-all duration-200'>
            Request access to the Investment Pitch
          </p>
        </a>
      </div>
    </div>
  );
};

export default Investor;
