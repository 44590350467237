import React from "react";
import mintie from "../../assets/images/characters/Untitled_Artwork.png";
import mintie1 from "../../assets/images/characters/Untitled_Artwork 1.png";
import mintie2 from "../../assets/images/characters/Untitled_Artwork 2.png";
import mintie3 from "../../assets/images/characters/Untitled_Artwork 3.png";
import mintie4 from "../../assets/images/characters/Untitled_Artwork 4.png";
import mintie5 from "../../assets/images/characters/Untitled_Artwork 5.png";
import mintie6 from "../../assets/images/characters/Untitled_Artwork 6.png";
import mintie7 from "../../assets/images/characters/Untitled_Artwork 7.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Import Swiper styles
import "swiper/css";
import "@splidejs/react-splide/css";
// import required modules
import { Keyboard, Autoplay, Pagination, Navigation } from "swiper";

const WhoUses = () => {
  const carasoleData = [
    { image: mintie, altImg: "img1", role: "Gamers" },
    { image: mintie1, altImg: "img2", role: "Artists" },
    { image: mintie2, altImg: "img3", role: "Entrepreneurs" },
    { image: mintie3, altImg: "img4", role: "Musicians" },
    { image: mintie4, altImg: "img5", role: "Educators" },
    { image: mintie5, altImg: "img6", role: "Vloggers" },
    { image: mintie6, altImg: "img7", role: "Gamers" },
    { image: mintie7, altImg: "img8", role: "Photographers" },
  ];
  return (
    <div className='h-max w-full bg-alt  '>
      <div className='h-full w-full '>
        <div className='text-secondary bg-white rounded-full text-2xl sm:text-5xl  py-5 px-10 w-max font-bold block mx-auto -rotate-0  -translate-y-10'>
          Who uses <span className='text-primary'>M</span>int
          <span className='text-primary'>F</span>lick ?
        </div>
        <div className=' w-full py-28  '>
          <Swiper
            spaceBetween={50}
            slidesPerView={5}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            keyboard={{
              enabled: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Keyboard, Autoplay, Pagination, Navigation]}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}>
            {carasoleData.map((data) => (
              <SwiperSlide className='flex flex-col justify-center  h-56 w-56 p-8'>
                <div className='flex flex-col justify-center items-center  h-full w-full'>
                  <img
                    src={data.image}
                    alt={data.altImg}
                    className='object-center'
                  />
                  <p className='text-primary text-center font-bold text-2xl caption w-fit  mt-12'>
                    {data.role}
                  </p>
                </div>
              </SwiperSlide>
            ))}{" "}
          </Swiper>
          {/* <img src={mintie} className=" h-96 w-auto scale-105 -mr-20"></img>
          <img src={mintie1} className=" h-96 w-auto scale-115"></img>
          <img src={mintie5} className=" h-96 scale-150 w-auto"></img>
          <img src={mintie2} className=" h-96 w-auto scale-115 "></img>
          <img src={mintie6} className=" h-96 w-auto  scale-105 -ml-20"></img> */}
        </div>
      </div>
    </div>
  );
};

export default WhoUses;
