import React from "react";
import SocialMedia2 from "./SocialMedia/SocialMedia2";
import Marketplace from "./Markeplace/Marketplace";
import Tokenomics from "../Tokenomics/Tokenomics";
import StillNotConvinced from "../StillNotConvinced/StillNotConvinced";
import Track from "./Track/Track";
import Roadmap from "./Roadmap/Roadmap";
import Superfans2 from "./Superfans/Superfans2";
import LiveStreams2 from "../LiveStreams/LiveStreams2";
import Community2 from "./CommunityChannels/Community2";
const WhyTheyUseIt = () => {
  return (
    <div className="h-max w-full why-background border-t-2 border-secondary ">
      <div className="h-full w-full">
        <div className="text-white   bg-primary rounded-full text-2xl sm:text-5xl  py-5 px-10 w-max font-bold block mx-auto  rotate-4 -mt-10">
          Why they use it ?
        </div>
        <div className="hidden lg:block h-full">
          <Track />
        </div>
        <div className="w-full z-10">
          <SocialMedia2></SocialMedia2>
          <Marketplace />

          <Superfans2></Superfans2>
          <LiveStreams2></LiveStreams2>
          <Community2></Community2>

          <Tokenomics />
          <Roadmap />
          <StillNotConvinced />
        </div>
      </div>
    </div>
  );
};

export default WhyTheyUseIt;
