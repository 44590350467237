import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import head from "../../assets/images/Mintie in plane.png";
import head_logo from "../../assets/images/logos/mintflick-head.svg";
import MintieIntro from "../MintieIntro/MintieIntro";
import { VANTA } from "vanta";
import whitepaper from "../../assets/MF whitepaper.pdf";
const Head = () => {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  useEffect(() => {
    VANTA.BIRDS({
      el: "#birds",
      mouseControls: true,
      touchControls: true,
      gyroControls: true,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color1: 0x2ce8e5,
      color2: 0x0,
      colorMode: "lerpGradient",
      birdSize: 0.5,
      quantity: 2.0,
      backgroundAlpha: 0.0,
    });
  }, []);
  return (
    <div className="w-full h-screen lg:h-[1920px] bg-head ">
      <div className="flex justify-center">
        <div
          id="birds"
          className="lg:h-[1920px] h-screen absolute w-full"
        ></div>
        {/* <MintieIntro /> */}
        <div className="w-full  h-fit lg:h-screen relative flex mx-24 xl:mx-52 items-center justify-between lg:-mt-24 space-x-24 ">
          <div
            id="left-section"
            className=" w-full  h-fit flex flex-col justify-center items-center lg:items-start mt-20 lg:mt-0 xl:scale-110"
          >
            <div className="scale-90 sm:scale-100 flex items-center ">
              <img
                alt="mintflick logo"
                src={head_logo}
                className="2xl:h-40 h-32 w-full -mx-4"
              />
              <p className="text-white  text-5xl font-semibold ml-4">
                MintFlick
              </p>
            </div>
            <div className="scale-95 sm:scale-100 flex items-start flex-col my-3 font-bold">
              <div className="bg-primary px-1 py-0.5">
                <p className="text-secondary 2xl:text-5xl lg:text-5xl text-4xl md:text-5xl">
                  DECENTRALIZED
                </p>
              </div>
              <p className="text-white 2xl:text-6xl lg:text-5xl text-4xl md:text-5xl">
                <Typewriter
                  options={{
                    strings: [
                      "Community",
                      "NFT Marketplace",
                      "Streaming",
                      "Concerts",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </p>
            </div>
            <div className="scale-90 sm:scale-100 flex ">
              <a
                target={"_blank"}
                href="https://drive.google.com/file/d/1DX_xNBrjQomUFf5VtmxSxpaAgDhszUnw/view"
                className="  cursor-pointer   whitespace-nowrap self-center  text-primary-600  bg-white  hover:bg-primary hover:text-white text-center  lg:text-md md:text-md 2xl:text-lg   md:px-4 md:py-2 px-3 py-2 font-semibold     duration-100 mr-2"
              >
                Download Whitepaper
              </a>
              <a
                href="#footer"
                smooth={true}
                duration={5000}
                className="  cursor-pointer whitespace-nowrap self-center    text-white  bg-primary-400  text-opacity-90  hover:bg-primary hover:text-white  text-center    lg:text-md md:text-md 2xl:text-lg   md:px-4 md:py-2 px-3 py-2   font-semibold     duration-100 mx-2"
              >
                BECOME A PARTNER
              </a>
            </div>
          </div>
          <div className="hidden lg:block scale-125 h-fit w-1/2">
            {/* <Lottie className="rounded-xl" options={defaultOptions} /> */}
            <img
              alt="hologram"
              src={head}
              className="  animate-mascotbounce transition duration-300 ease-in-out "
            />
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center 2xl:-mt-56 lg:-mt-40 -mt-14 cursor-pointer">
        {/* <Link to="social-media" smooth={true} duration={1000}>
          <div className="flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="2xl:h-7 2xl:w-7 lg:h-5 lg:w-5 h-5 w-5 2xl:mx-auto lg:ml-2 lg:mb-2 mb-1 ml-2 p-1  opacity-50 hover:opacity-100 "
              viewBox="0 0 20 20"
              fill="#fff"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              width="42"
              height="62"
              viewBox="0 0 42 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="2xl:h-14 2xl:w-14 lg:h-9 lg:w-9 h-9 w-9   pt-2 animate-bounce  hover:opacity-50 hover:animate-none transition-all duration-200 transform"
            >
              <g opacity="0.5">
                <rect
                  x="0.5"
                  y="0.5"
                  width="41"
                  height="61"
                  rx="20.5"
                  fill="#C4C4C4"
                  stroke="white"
                />
                <path
                  d="M18.5 15.5C18.5 13.8431 19.8431 12.5 21.5 12.5C23.1569 12.5 24.5 13.8431 24.5 15.5V24.5C24.5 26.1569 23.1569 27.5 21.5 27.5C19.8431 27.5 18.5 26.1569 18.5 24.5V15.5Z"
                  fill="white"
                  stroke="white"
                />
              </g>
            </svg>
          </div>
        </Link> */}
      </div>
    </div>
  );
};

export default Head;
