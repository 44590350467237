import React, { useEffect, useState } from "react";
import SectionHeadText from "../SectionHead/SectionHeadText";
import tokenomics from "../../assets/images/tokenomics.png";
import newTokenomics from "../../assets/images/newTokenomics.png";
import CircleType from "circletype";

var LineChart = require("react-chartjs-2").Line;

const Tokenomics = () => {
  const [animate, setAnimate] = useState(false);

  console.log(animate);

  useEffect(() => {
    setAnimate(true);
    // Instantiate `CircleType` with an HTML element.
    //  new CircleType(document.getElementById('text-circle')).radius(200).dir(1);
    //  new CircleType(document.getElementById('text-circle2')).radius(200).dir(1);
    //  new CircleType(document.getElementById('text-circle3')).radius(200).dir(1);
    //  new CircleType(document.getElementById('text-circle4')).radius(200).dir(1);
    //  new CircleType(document.getElementById('text-circle5')).radius(200).dir(1);
    //  new CircleType(document.getElementById('text-circle6')).radius(200).dir(1);
  }, []);

  return (
    <div
      id='tokenomics'
      className='h-max w-full   mt-72 mb-48 pt-2'
      onMouseOver={() => setAnimate(true)}>
      <div className='h-full w-full  '>
        <div className='w-full'>
          <h1 className='text-white 2xl:text-6xl lg:text-4xl md:text-4xl text-3xl text-center font-black 2xl:mt-1 lg:mt-0.5'>
            TOKENOMICS
          </h1>
          <p className='text-white bg-primary px-8 py-2 w-max block mx-auto rounded-full text-center 2xl:text-3xl font-black  text-xl 2xl:mt-1 lg:mt-0 '>
            300 Million FLICK Tokens
          </p>
        </div>
        <div className=' block mx-auto  mt-20'>
          <div className='md:w-1/2 mx-auto'>
            <img
              src={tokenomics}
              className='h-3/4 w-3/4 sm:w-2/3   sm:h-2/3 mt-12 mx-auto p-3 animate-spin-slow '
              alt='Tokeniomics'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
