import React from "react";
import SocialMediaCard2 from "../../SocialMedia/SocialMediaCard2";
import Card3 from "../Card3";
import socialImg1 from "../../../assets/images/socialImg1.png";
import socialImg2 from "../../../assets/images/socialImg2.png";
import socialImg3 from "../../../assets/images/socialImg3.png";
const SocialMedia = () => {
  return (
    <div className="relative flex items-center justify-center my-40  px-10 gap-10 xl:gap-24 2xl:gap-36">
      <div className=" z-50 ">
        <Card3
          headBefore="NFTfied"
          headTitle="SOCIAL MEDIA"
          headAfter={
            "Blogs, Pictures, Videos, Music, Tweets, everything else you can think of but NFTs. "
          }
          desc={
            "Always wanted to sell that Photograph you captured on your trip to Himalayas? Post your favorite Digital Painting & make it a NFT. You can set royalties for every sale"
          }
          cards={
            <div className="scale-75 group absolute z-50 left-1/2 -translate-x-1/2 lg:translate-x-1/2 translate-y-3">
              <div className="w-full flex justify-center items-start group  ">
                <div className="h-60   w-60 -rotate-6 -mr-36 sm:-mr-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-mr-3 ">
                  <SocialMediaCard2
                    post={socialImg2}
                    videoname="Trip to Las Vegas"
                    name="orion"
                    price="50"
                    days="4 days ago"
                  />
                </div>
                <div className="h-72   w-60 rounded-sm bg-alt/60 backdrop-blur-sm p-2 rounded-md   shadow-lg shadow-gray-900 scale-105 group-hover:scale-100 hover:shadow-none  z-10  transform transition-all duration-500 ">
                  <img
                    className="h-full    w-full object-cover rounded-md"
                    src={socialImg1}
                    alt="Girl in a jacket"
                    width="500"
                    height="600"
                  ></img>
                </div>
                <div className="h-60   w-60 rotate-6 -ml-36 sm:-ml-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 ">
                  <SocialMediaCard2
                    post={socialImg3}
                    videoname="My Grumpy Cat"
                    name="orion"
                    price="50"
                    days="15 minutes ago"
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="hidden lg:block group  z-50  ">
        <div className="w-full flex justify-center items-start group  ">
          <div className="h-60   w-60 -rotate-6 -mr-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-mr-3 ">
            <SocialMediaCard2
              post={socialImg2}
              videoname="Trip to Las Vegas"
              name="orion"
              price="50"
              days="4 days ago"
            />
          </div>
          <div className="h-72   w-60  bg-alt/60 backdrop-blur-sm p-2 rounded-md   shadow-lg shadow-gray-900 scale-105 group-hover:scale-100 hover:shadow-none  z-10  transform transition-all duration-500 ">
            <img
              className="h-full    w-full object-cover rounded-md"
              src={socialImg1}
              alt="Girl in a jacket"
              width="500"
              height="600"
            ></img>
          </div>
          <div className="h-60   w-60 rotate-6 -ml-20 group-hover:rotate-0 transform transition-all duration-500 delay-75 ease-in-out group-hover:-ml-3 ">
            <SocialMediaCard2
              post={socialImg3}
              videoname="My Grumpy Cat"
              name="orion"
              price="50"
              days="15 minutes ago"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
