import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import tokenomics from "../../assets/images/tokenomics.png";
import head from "../../assets/images/Mintie in plane.png";
import head_logo from "../../assets/images/logos/mintflick-head.svg";
import { VANTA } from "vanta";
import whitepaper from "../../assets/MF whitepaper.pdf";
const Pitch = () => {
  return (
    <div className='w-full h-full bg-opacity-25 '>
      <div className='w-full  h-fit lg:h-screen relative  flex flex-col   items-center justify-center lg:-mt-24 xl:space-x-24 px-4'>
        <div className=' w-full  h-fit flex flex-col justify-center items-center  mt-20 lg:mt-0 xl:scale-110'>
          <div className='scale-90 xl:scale-100 flex items-center '>
            <img
              alt='mintflick logo'
              src={head_logo}
              className='2xl:h-40 h-32 w-full  mx-4'
            />
            <p className='text-white  text-5xl font-semibold ml-4'>MintFlick</p>
          </div>
          <div className='scale-95 xl:scale-100 flex items-start flex-col my-3 font-bold'>
            <div className='bg-primary px-1 py-0.5'>
              <p className='text-secondary 2xl:text-5xl lg:text-5xl text-4xl md:text-5xl'>
                DECENTRALIZED
              </p>
            </div>
            <p className='text-white 2xl:text-6xl lg:text-5xl text-4xl md:text-5xl'>
              <Typewriter
                options={{
                  strings: [
                    "Community",
                    "NFT Marketplace",
                    "Streaming",
                    "Concerts",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </p>
          </div>
        </div>

        <div className='flex flex-row grid-cols-3 gap-6 mt-12 '>
          <div className='  flex flex-col items-center justify-center '>
            <div className='   font-bold text-2xl text-white'>Web 1</div>
            <div className='px-4 py-2 bg-[#282052] shadow font-bold text-lg mt-4'>
              The information Economy
            </div>
          </div>
          <div className='  flex flex-col items-center justify-center '>
            <div className='   font-bold text-2xl text-white'>Web 2</div>
            <div className='px-4 py-2 bg-[#282052] shadow font-bold text-lg mt-4'>
              The Platform Economy
            </div>
          </div>
          <div className='  flex flex-col items-center justify-center '>
            <div className='   font-bold text-2xl text-white'>Web 3</div>
            <div className='px-4 py-2 bg-[#282052] shadow font-bold text-lg mt-4'>
              The Ownership Economy
            </div>
          </div>
        </div>
      </div>
      <div className=' flex flex-col gap-y-12 xl:px-48 px-4 text-white mt-24'>
        <div className='xl:px-48'>
          <div className='text-3xl font-bold  px-4 py-2 bg-[#2ce8e5] shadow text-[#282052]  w-fit border-4 border-white'>
            MintFlick Revolutionising Web3 Content Ecosystem
          </div>
        </div>
        <div className='xl:px-48'>
          <div className='px-4 py-2 bg-[#282052] shadow font-bold text-lg w-fit'>
            Why?
          </div>
          <div className='px-4 py-2 bg-[#fff] text-[#282052] shadow font-semibold text-lg w-fit'>
            Need of a single Decentralized Social Content platform for Creating,
            Selling & Owning Content with Social experience with trust and
            ownership including value creation and earnings participation.
          </div>
        </div>
        <div className='xl:px-48'>
          <div className='px-4 py-2 bg-[#282052] shadow font-bold text-lg w-fit'>
            Why Now?
          </div>
          <div className='px-4 py-2 bg-[#fff] text-[#282052] shadow font-semibold text-lg w-fit'>
            Growing Social Data Threats, Ownership experience, Creators Economy,
            exponentially increasing content, discontent among users for current
            platforms, growing Blockchain adoption.
          </div>
        </div>
        <div className='xl:px-48'>
          <div className='px-4 py-2 bg-[#282052] shadow font-bold text-lg w-fit'>
            How?
          </div>
          <div className='px-4 py-2 bg-[#fff] text-[#282052] shadow font-semibold text-lg w-fit'>
            Incentivize users and creators for posting & moderating content with
            Crypto Token. Royalties for secondary NFT sales enabling a mode of
            passive income for creators. A Decentralised Social media brings
            Transparency, Traceability & Trust to its users
          </div>
        </div>
      </div>

      <div className='flex flex-col align-middle items-center mt-12 xl:px-48 px-4'>
        <div className='xl:mx-48 mx-4 text-3xl font-bold  px-4 py-2 bg-secondary shadow text-primary border-4  w-fit mb-12 mt-36 border-white '>
          Features
        </div>
        <table class='table-auto bg-slate-700 gap-4 table-zebra sm:table-normal shadow-mintflick'>
          <thead>
            <tr className='align-middle text-center items-center text-white'>
              <th>Features</th>
              <th className='items-center align-middle border-x border-t  '>
                <img
                  alt='mintflick logo'
                  src={head_logo}
                  className='2xl:h-8 h-8 w-full   '
                />
                MintFlick
              </th>
              <th>Huddln</th>
              <th>Steemit</th>
              <th>Superrare</th>
              <th>Twitch</th>
              <th>Instagram</th>
            </tr>
          </thead>
          <tbody>
            <tr className='align-middle text-center items-center'>
              <td className='text-white'>Social Ownership</td>
              <td className='border-x '>✔️</td>
              <td> </td>
              <td> ✔️</td>
              <td> </td>
              <td></td>
              <td></td>
            </tr>
            <tr className='align-middle text-center items-center'>
              <td className='text-white'>NFT Content</td>
              <td className='border-x'>✔️</td>
              <td>✔️ </td>
              <td> </td>
              <td>✔️</td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='align-middle text-center items-center'>
              <td className='text-white'>Royalties</td>
              <td className='border-x'>✔️</td>
              <td> </td>
              <td> </td>
              <td>✔️</td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='align-middle text-center items-center'>
              <td className='text-white'>Video Streaming</td>
              <td className='border-x'>✔️</td>
              <td></td>
              <td></td>
              <td> </td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr className='align-middle text-center items-center'>
              <td className='text-white'>Community Governed</td>
              <td className='border-x'>✔️</td>
              <td> </td>
              <td>✔️</td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='align-middle text-center items-center'>
              <td className='text-white '>Immutable (IPFS)</td>
              <td className='border-x '>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className='align-middle text-center items-center'>
              <td className='text-white '>Token Gated Content</td>
              <td className='border-x border-b'>✔️</td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='xl:px-48 mt-12  w-full items-center text-center align-middle '>
        <div className='items-center text-center align-middle flex flex-col xl:mx-48 mx-4'>
          <div className='xl:mx-48   text-3xl font-bold    px-4 flex  sm:w-max  py-2 bg-secondary shadow text-primary border-4   mb-12 mt-36 border-white '>
            Content shared across current mainstream platforms
          </div>
        </div>

        <div className='items-baseline align-middle  flex justify-evenly  w-full px-4  gap-x-2'>
          <div className='h-48 w-48 border-black border-4 border-b-0 shadow-mintflick hover:border-white  bg-primary transition-all duration-300'></div>
          <div className='h-36 w-48 border-black border-4 border-b-0 shadow-mintflick hover:border-white   bg-primary transition-all duration-300'></div>
          <div className='h-24 w-48 border-black border-4 border-b-0 shadow-mintflick hover:border-white   bg-primary transition-all duration-300'></div>
          <div className='h-12 w-48 border-black border-4 border-b-0 shadow-mintflick hover:border-white   bg-primary transition-all duration-300'></div>
          <div className='h-6 w-48 border-black border-4 border-b-0 shadow-mintflick hover:border-white  bg-primary transition-all duration-300'></div>
        </div>
        <div className='w-full h-2 xl:h-4 bg-black   flex justify-evenly items-center align-middle '>
          {/* <div className='rounded-full hidden bg-white h-4 w-4 border-4 border-slate-800'></div>
          <div className='rounded-full hidden bg-white h-4 w-4 border-4 border-slate-800'></div>
          <div className='rounded-full hidden bg-white h-4 w-4 border-4 border-slate-800'></div>
          <div className='rounded-full hidden bg-white h-4 w-4 border-4 border-slate-800'></div>
          <div className='rounded-full hidden bg-white h-4 w-4 border-4 border-slate-800'></div> */}
        </div>

        <div className='  align-middle  flex justify-evenly  w-full mt-6 '>
          <div className='  w-48  '>
            <p className='font-bold text-white'>Blog Posts</p> New 5M Blogs/Day
            - Wordpress
          </div>
          <div className='  w-48  '>
            <p className='font-bold text-white'>Picture Content</p> New 4M
            Pcitures/Day - Instagram
          </div>
          <div className='  w-48  '>
            <p className='font-bold text-white'>Videos</p> New 720000 Hours/Day
            - Youtube
          </div>
          <div className='  w-48  '>
            <p className='font-bold text-white'>Music</p> New 60000 Tracks/Day -
            Spotify
          </div>
          <div className='  w-48  '>
            <p className='font-bold text-white'>Art/NFTs </p>
            New 5000 Mints/Day - Opensea
          </div>
        </div>

        <div className='text-2xl text-primary font-bold mt-4'>
          🌟Over 10M+ Content shared daily
        </div>
      </div>

      <div className='xl:px-48 mt-12  w-full items-center text-center '>
        <div className='justify-between flex mt-36 xl:mx-48 mx-4 align-middle'>
          <div className=' text-3xl font-bold  px-4 py-2 bg-secondary shadow text-primary border-4  w-fit mb-12  border-white'>
            Market Size
          </div>

          <div className='text-primary font-semibold align-middle items-center'>
            source :
            <a
              className='text-white'
              href='https://www.coindesk.com/business/2022/01/20/jefferies-sees-the-nft-market-reaching-more-than-80-billion-in-value-by-2025/'
              target={"_blank"}
              rel='noopener '>
              coindesk.com
            </a>
          </div>
        </div>
        <div className='items-baseline align-middle  flex justify-evenly  w-full px-4 gap-x-4'>
          <div className='h-4 w-48 border-black border-4 border-b-0 shadow-mintflick hover:bg-secondary  bg-white transition-all duration-300'></div>
          <div className='h-32 w-48 border-black border-4 border-b-0 shadow-mintflick hover:bg-secondary   bg-white transition-all duration-300'></div>
          <div className='h-80 w-48 border-black border-4 border-b-0 shadow-mintflick hover:bg-secondary   bg-white transition-all duration-300'></div>
        </div>
        <div className='w-full h-2 xl:h-4 bg-black   flex justify-evenly items-center align-middle '></div>

        <div className='  align-middle  flex justify-evenly  w-full mt-6 '>
          <div className='  w-48  '>
            <p className='font-bold text-white'>$2.5B</p> 2021
          </div>
          <div className='  w-48  '>
            <p className='font-bold text-white'>$35B</p> 2022
          </div>
          <div className='  w-48  '>
            <p className='font-bold text-white'>$80B</p> 2025
          </div>
        </div>

        <div className='text-2xl text-primary font-bold mt-4'>
          CAGR of 35.0% from 2022 to 2025
        </div>
        <div className='font-semibold text-2xl xl:text-3xl italic text-white mt-4 px-4'>
          "Collectibles and NFT Market Size Forecast to reach $1 Trillion by
          2032 "
          <a
            className='font-normal first-letter:opacity-40'
            href='https://www.prnewswire.com/news-releases/collectibles-and-nft-market-size-forecast-to-reach-1-trillion-by-2032-research-update-by-market-decipher-301596803.html'
            target='_blank'>
            - prnewswire
          </a>
        </div>
      </div>

      <div className='flex flex-col align-middle items-center mt-24'>
        <div className='text-3xl font-bold  px-4 py-2 bg-[#282052] shadow text-[#2ce8e5]  w-fit border-4 border-white mb-6'>
          Roadmap
        </div>
        <ul className='steps steps-vertical'>
          <li className='step step-success' data-content='✓'>
            Inception
          </li>
          <li className='step step-success' data-content='✓'>
            Hackathons - ETHGlobal, ETHOnline
          </li>
          <li className='step step-success' data-content='✓'>
            Sponsorships - Livepeer, Superfluid, Audius
          </li>
          <li className='step step-success' data-content='✓'>
            Grants - Polygon, Livepeer, Protocol Labs
          </li>
          <li className='step step-success' data-content='✓'>
            MVP deployed to testnet
          </li>
          <li className='step step-success' data-content='✓'>
            Invite Only Beta Released
          </li>
          <li className='step ' data-content='●'>
            1K Users Onboarded{" "}
          </li>

          <li className='step ' data-content='●'>
            Seed Round
          </li>
          <li className='step ' data-content='●'>
            Multi-chain Integration
          </li>
          <li className='step ' data-content='●'>
            Mobile App
          </li>
          <li className='step ' data-content='●'>
            IDO
          </li>
          <li className='step ' data-content='●'>
            Product Hunt Launch
          </li>
        </ul>
      </div>

      <div className='flex flex-col align-middle items-center mt-24'>
        <div className='text-3xl font-bold  px-4 py-2 bg-[#282052] shadow text-[#2ce8e5]  w-fit border-4 border-white'>
          Tokenomics
        </div>
        <div className=' font-semibold text-lg flex mt-12 mx-4'>
          <p className='font-bold text-lg xl:text-xl text-white mr-2 '>
            $FLICK
          </p>
          token will be minted with a Max supply of 300M tokens
        </div>
        <div className='md:w-1/2 mx-auto'>
          <img
            src={tokenomics}
            className='h-3/4 w-3/4 xl:w-2/3   xl:h-2/3 mt-6 mx-auto p-3   drop-shadow-xl hover:scale-[1.01] transition-all duration-300'
            alt='Tokeniomics'
          />
        </div>

        <div className='px-4 xl:px-48 mt-12  w-full items-center text-center '>
          <div className='justify-middle items-center flex mt-36 mx-4 xl:mx-48 flex-col'>
            <div className=' text-2xl font-bold  px-4 py-2 w-full mb-6  text-white'>
              We are raising pre-seed round of
            </div>
          </div>

          <div className='justify-middle items-center flex   xl:mx-48 flex-col'>
            <div className=' text-5xl xl:text-7xl font-extrabold  px-8 py-6   shadow-mintflick bg-primary  w-fit mb-12  text-white    transition-all duration-500'>
              USD 500,000
            </div>
          </div>
        </div>
      </div>

      <div className='flex align-middle items-center flex-col hidden'>
        <div className='bg-white h-80 w-2'></div>
        <div className='flex flex-row'>
          <div className='bg-white w-96 h-2'></div>
          <div className='bg-white w-96 h-2'></div>
        </div>
        <div className='bg-white h-80 w-2'></div>
      </div>

      <div className='flex align-middle items-center flex-col h-screen'>
        <div className='text-7xl text-white flex align-middle items-center mt-96 font-extrabold'>
          Thank You!
        </div>
      </div>
    </div>
  );
};

export default Pitch;
