import React from "react";

const Timing = (props) => {
  return (
    <>
      <p className="text-white lg:w-2/5 w-5/6 text-start 2xl:ml-6 lg:ml-4 ml-4 2xl:mb-2 lg:mb-1 mb-1 2xl:text-xl lg:text-md">
        {props.value.day}
      </p>
      <div className="lg:w-2/5 w-5/6 flex justify-between bg-alt 2xl:px-6 2xl:py-4 lg:px-3 lg:py-2 px-4 py-1.5 text-primary 2xl:text-xl lg:text-md">
        <p>{props.value.time}</p>
        <p>{props.value.session}</p>
      </div>
    </>
  );
};

export default Timing;
