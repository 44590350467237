import React from "react";
import SectionHeadText from "../../../components/SectionHead/SectionHeadText";
import discord from "../../../assets/images/discord.svg";
import Social from "../../../components/Footer/Social";

const Help = () => {
  return (
    <div className="w-full h-max mt-40">
      <div className="h-full w-full">
        <div className="w-full">
          <SectionHeadText title="HELP" />
        </div>
        <div className="w-full flex flex-col items-center 2xl:mt-14 lg:mt-10 mt-8">
          <a href="https://discord.gg/xZavZyAbx4" target="_blank">
            <img
              className="lg:mx-3 md:mx-1.5 mx-2 lg:h-14 2xl:h-20 md:h-16 h-16"
              src={discord}
            ></img>
          </a>
          <p className="text-white 2xl:text-2xl lg:text-lg text-md text-center 2xl:mt-5 lg:mt-2.5 mt-2.5">
            Join Official Discord & connect
            <br /> with the community!
          </p>
        </div>
        <Social />
      </div>
    </div>
  );
};

export default Help;
