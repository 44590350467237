import React from "react";
import sample from "../../assets/images/sample.svg";
import token from "../../assets/images/token.svg";

const SocialMediaCard2 = (props) => {
  return (
    <div className='h-fit  w-60  p-2  rounded-md bg-alt/60  shadow-md backdrop-blur-[24px]  shadow-gray-900 hover:scale-101 transform transition-all duration-200'>
      <div className='h-52 w-full bg-white rounded-md'>
        <img
          className='h-full  object-cover rounded-md'
          src={props.post}
          alt='Social post'
          width='500'
          height='600'></img>
      </div>

      <div className='w-full my-2   flex justify-between items-center '>
        <img src={sample} className='h-12 w-12 '></img>
        <div className='ml-1 flex-grow '>
          <div className='flex justify-between'>
            <p className='text-white font-medium text-sm'>{props.videoname}</p>
            <div className='flex h-fit w-fit items-center mr-2'>
              <img src={token} className=' h-4 w-4  '></img>
              <p className='text-white  text-sm  ml-1'>{props.price}</p>
            </div>
          </div>
          <div className='flex items-center'>
            <p className='text-white  opacity-50 text-sm'>{props.name}</p>
            <p className='text-white opacity-80 ml-2 text-xs'>{props.days}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaCard2;
