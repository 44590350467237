import React from "react";
import dbeats from "../../../assets/images/logo.svg";
import event_logo from "../../../assets/images/eventLogo.svg";
import { Link } from "react-scroll";

const Head = () => {
  return (
    <div className="h-screen w-full">
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="flex items-center">
          <img
            src={dbeats}
            className="2xl:h-32 2xl:w-32 lg:h-24 lg:w-24 md:h-20 md:w-20 h-20 w-20"
          ></img>
          <p className="2xl:ml-2 2xl:text-6xl lg:text-4xl md:text-4xl text-3xl text-white">
            DBeats
          </p>
        </div>
        <div>
          <img src={event_logo} className="2xl:h-44 lg:h-32 h-20 w-auto"></img>
        </div>
        <div className="flex flex-col bg-secondary 2xl:px-16 2xl:py-5 lg:px-10 lg:py-2.5 px-6 py-2.5 mt-5 lg:mt-0 text-center text-white border rounded-sm">
          <p className="2xl:text-sm lg:text-xs text-sm">April 2 - 3, 2022 </p>
          <p className="2xl:text-3xl lg:text-xl text-lg 2xl:mt-2 lg:mt-1 mt-1 font-semibold">
            ₹ 50,000 in Prizes
          </p>
        </div>
        <div>
          <p className="text-white 2xl:text-3xl lg:text-xl text-md text-center 2xl:mt-16 lg:mt-8 mt-10 px-2">
            A web3 event for creators, gamers, streamers &<br /> artists of all
            shapes & sizes
          </p>
        </div>
        <div className="flex w-full justify-center 2xl:mt-10 lg:mt-5 mt-10 cursor-pointer">
          <Link to="prizes" smooth={true} duration={1000}>
            <div className="flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="2xl:h-7 2xl:w-7 lg:h-5 lg:w-5 h-5 w-5 2xl:mx-auto lg:ml-2 lg:mb-2 mb-1 ml-2 p-1  opacity-50 hover:opacity-100 "
                viewBox="0 0 20 20"
                fill="#fff"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <svg
                width="42"
                height="62"
                viewBox="0 0 42 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="2xl:h-14 2xl:w-14 lg:h-9 lg:w-9 h-9 w-9   pt-2 animate-bounce  hover:opacity-50 hover:animate-none transition-all duration-200 transform"
              >
                <g opacity="0.5">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="41"
                    height="61"
                    rx="20.5"
                    fill="#C4C4C4"
                    stroke="white"
                  />
                  <path
                    d="M18.5 15.5C18.5 13.8431 19.8431 12.5 21.5 12.5C23.1569 12.5 24.5 13.8431 24.5 15.5V24.5C24.5 26.1569 23.1569 27.5 21.5 27.5C19.8431 27.5 18.5 26.1569 18.5 24.5V15.5Z"
                    fill="white"
                    stroke="white"
                  />
                </g>
              </svg>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Head;
