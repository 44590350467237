import React from "react";
import SectionHeadText from "../SectionHead/SectionHeadText";
import SectionParaText from "../SectionHead/SectionParaText";
import { Link } from "react-scroll";

const StillNotConvinced = () => {
  return (
    <div className="h-max w-full  2xl:py-60 lg:py-40 md:py-36 py-24 bg-alt">
      <div className="h-full w-full">
        <div className="w-full">
          <SectionHeadText title="STILL NOT CONVINCED?" />

          <SectionParaText
            para={
              <p className="px-4 md:px-0">
                what if we say you can earn badges, NFT airdrops & access to{" "}
                <br className="hidden md:block" />
                our awesome creators community. All of this for joining early.
              </p>
            }
          />
          <div className="flex w-full justify-center md:mt-10 mt-7 z-50">
        

            <a
              href="https://beta.mintflick.app"
               
              target="_blank"
              rel="noopener noreferrer"
              className="  cursor-pointer whitespace-nowrap self-center    bg-white text-primary text-opacity-90  hover:bg-primary hover:text-white border-primary border-2 text-center    lg:text-md md:text-md 2xl:text-lg text-sm md:px-4 md:py-2 px-3 py-2   font-semibold rounded hover:scale-95 duration-300 mx-2"
            >
                            GO TO BETA APP

            </a>
          </div>
        </div>
        {/* <div className="flex w-full justify-center ">
          
        </div> */}
      </div>
    </div>
  );
};

export default StillNotConvinced;
