import React from "react";
import DisclosureComponent from "./Disclosure";
import data from "./Data";
import SectionHeadText from "../../../components/SectionHead/SectionHeadText";

const FAQ = () => {
  return (
    <div className="h-max w-full mt-40">
      <div className="h-full w-full">
        <div className="w-full">
          <SectionHeadText title="FAQS" />
        </div>
        <div className="w-full h-max mt-20">
          {data.map((value, i) => {
            return (
              <div className="flex flex-col items-center">
                <DisclosureComponent value={value} key={i} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
