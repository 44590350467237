import React, { useState } from "react";
import forward from "../../assets/images/components/forwardArrow.svg";
import back from "../../assets/images/components/backArrow.svg";
import { Link } from "react-router-dom";
import faqs from "../../assets/faqs";
const FAQs = () => {

  const [index, setIndex] = useState(0);

  const showPrev = () => {
    if (index === 0) return;
    setIndex(index - 1);
  };

  const showNext = () => {
    if (index === faqs.length - 1) return;
    setIndex(index + 1);
  };

  return (
    <div className="h-max w-full  2xl:pb-60 2xl:pt-0 lg:pb-40 lg:pt-0 md:pb-36 md:pt-0 pb-24 mt-0 bg-alt">
      <div className="h-full w-full">
        <div className="w-full">
          <div className="text-center w-max block mx-auto 2xl:px-8 lg:px-6 2xl:pt-2 lg:pt-1.5 lg:pb-2.5 2xl:pb-4 md:px-6 md:pb-2.5 md:pt-1.5 px-5 pb-2 pt-1 rounded-full bg-white 2xl:text-6xl lg:text-4xl md:text-4xl text-2xl text-center font-bold text-alt">
            FAQs
          </div>
          <div className="2xl:mt-24 lg:mt-16 md:mt-14 mt-12 w-full flex flex-col justify-center items-center">
            <p className="text-primary 2xl:text-5xl lg:text-3xl md:text-3xl text-lg font-semibold">
              {faqs[index].question}
            </p>
            <div className="flex 2xl:mt-14 lg:mt-10 md:mt-8 mt-6 justify-center items-center md:mx-10 lg:mx-20">
              <button
                onClick={showPrev}
                className="text-white 2xl:p-4 lg:p-2.5 md:p-3 p-2.5 text-center font-bold rounded-full bg-primary flex-grow"
              >
                <img
                  src={back}
                  className="2xl:h-5 lg:h-4 2xl:w-5 lg:w-4 md:h-4 md:w-4 h-4 w-4"
                ></img>
              </button>
              <div className="text-center 2xl:mx-32 lg:mx-20 md:mx-20 mx-4 2xl:text-3xl lg:text-xl md:text-lg text-sm font-normal text-white">
              {faqs[index].answer}
              </div>
              <button
                onClick={showNext}
                className="text-white text-center font-bold 2xl:p-4 lg:p-2.5 md:p-3 p-2.5 rounded-full bg-primary flex-grow"
              >
                <img
                  src={forward}
                  className="2xl:h-5 lg:h-4 2xl:w-5 lg:w-4 md:h-4 md:w-4 h-4 w-4"
                ></img>
              </button>
            </div>
          </div>
          <Link to="/faqs">
            <p className="w-full text-center text-primary underline 2xl:text-2xl lg:text-lg 2xl:mt-14 lg:mt-10 md:mt-8 mt-6">
              View all
            </p>
          </Link>
          <div className="flex justify-center items-center 2xl:mt-24 lg:mt-16 md:mt-14 mt-12">
            {faqs.map((value, key) => {
              return (
                <div
                  key={key}
                  className={
                    key === index
                      ? `rounded-full 2xl:h-5 2xl:w-5 lg:h-3.5 lg:w-3.5 md:h-3 md:w-3 h-2 w-2 lg:mx-1 mx-1 2xl:mx-1.5 bg-primary`
                      : `rounded-full 2xl:h-5 2xl:w-5 lg:h-3.5 lg:w-3.5 md:h-3 md:w-3 h-2 w-2 lg:mx-1 mx-1 2xl:mx-1.5 bg-white`
                  }
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
