import React from "react";
import AwesomeTeam from "../AwesomeTeam/AwesomeTeam";
import Community from "../CommunityChannels/Community";
import Footer from "../Footer/Footer";
import Deck from "../Head/Deck";
import Head from "../Head/Head";
import LiveStreams from "../LiveStreams/LiveStreams";
import Marketplace from "../Marketplace/Marketplace";
import Partners from "../OurPartners/Partners";
import SocialMedia from "../SocialMedia/SocialMedia";
import StillNotConvinced from "../StillNotConvinced/StillNotConvinced";
import Superfans from "../Superfans/Superfans";
import Tokenomics from "../Tokenomics/Tokenomics";
// import WhoUses from "../WhoUsesDbeats/WhoUses";
import Featured from "../Featured/Featured";
import WhatsMintflick from "../WhatsMintFlick/WhatsMintflick";
import MintieIntro from "../MintieIntro/MintieIntro";
import WhoUses from "../WhoUsesMintflick/WhoUses";
import WhyTheyUseIt from "../WhyTheyUseIt/WhyTheyUseIt";
import Navbar from "../Navbar/Navbar";
import FAQs from "../FAQs/FAQs";

const LandingPage = () => {
  return (
    <>
      <Head />
      <Deck />
      <WhoUses />
      <WhyTheyUseIt />
      {/* <WhatsMintflick /> */}
      {/* <SocialMedia />
      <Marketplace />
      <Superfans />
      <LiveStreams />
      <Community /> */}
      {/* <WhoUses /> */}
      {/* <StillNotConvinced /> */}
      {/* <Featured /> */}
      <FAQs />
      <Partners />
      <AwesomeTeam />
      <Footer />
    </>
  );
};

export default LandingPage;
