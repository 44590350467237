import React from "react";
import QuestionComponent from "./QuestionComponent";
import faqs from "../assets/faqs";
const FAQs = () => {


  return (
    <div className="h-max w-full">
      <div className="h-full w-full">
        <div className="h-max w-full bg-primary 2xl:py-20 lg:py-12 md:py-12 py-10 flex flex-col justify-center items-center">
          <div className="text-center w-max block mx-auto 2xl:px-8 lg:px-6 2xl:pt-2 lg:pt-1.5 lg:pb-2.5 2xl:pb-4 md:px-6 md:pb-2.5 md:pt-1.5 px-5 pb-2 pt-1 rounded-full bg-white 2xl:text-6xl lg:text-4xl md:text-4xl text-2xl text-center font-bold text-alt">
            FAQs
          </div>
          <p className="text-alt 2xl:text-2xl lg:text-xl text-center 2xl:mt-8 lg:mt-5 md:mt-4 mt-4 font-bold">
            Hello people! got some burning questions?{" "}
            <br className="block md:hidden" /> Dont worry
            <br className="md:block hidden" />
            we’ve answered some freqeuntly asked questions for you already.
          </p>
        </div>
        <div className="h-max w-full bg-alt">
          {faqs.map((value, key) => {
            return (
              <>
                <QuestionComponent key={key} value={value} />
                <hr className="w-full " />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
