import React from "react";

const SectionParaText = (props) => {
  return (
    <div className="text-white 2xl:text-3xl lg:text-xl md:text-xl text-md  md:font-normal text-center 2xl:mt-7 lg:mt-3.5 md:mt-3 mt-2">
      {props.para}
    </div>
  );
};

export default SectionParaText;
