import React from "react";
import SectionHeadText from "../../../components/SectionHead/SectionHeadText";
import data from "./Data";
import Timing from "./Timing";

const Schedule = () => {
  return (
    <div className="h-max w-full mt-40">
      <div className="h-full w-full">
        <div className="w-full">
          <SectionHeadText title="SCHEDULE" />
        </div>
        <div className="w-full h-max mt-20">
          {data.map((value, i) => {
            return (
              <div className="flex flex-col items-center 2xl:mt-14 lg:mt-8 mt-6">
                <Timing value={value} key={i} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
