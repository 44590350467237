import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import Navbar from "./components/Navbar/Navbar";
import Stream3weekend from "./EventPages/Stream3Weekend/Stream3weekend";
import FAQs from "./FAQPage/FAQs";
import Pitch from "./EventPages/Pitch/pitch";

function App() {
  return (
    <>
      <Router>
        <div className='h-full w-full'>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<LandingPage />}></Route>
            <Route exact path='/event' element={<Stream3weekend />}></Route>
            <Route exact path='/faqs' element={<FAQs />}></Route>
            <Route exact path='/pitch' element={<Pitch />}></Route>
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
